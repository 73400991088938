@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700&display=swap');

/* https://websemantics.uk/tools/responsive-font-calculator/ */
@media (min-width: 1px) {
  :root {
    font-size: calc(0.625rem + ((1vw - 0.01px) * 0.3127));
    min-height: 0vw;
  }
}
@media (min-width: 1920px) {
  :root {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans JP', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
